import React from 'react';

import Layout from '../components/Layout';
import { Link } from 'gatsby';
import Sidebar from '../components/Sidebar';
const IndexPage = () => (
  <Layout>
      <div className="container-fluid mx-auto m-4 mt-10">

      <strong>WMT Dependency Annotations</strong>
<br/><br/>
  WMT annotations are described in the paper <i>Improvements to Syntax-based Machine Translation using Ensemble Dependency Parsers </i>presented at The Second Workshop on Hybrid Approaches to Translation (HyTra) at ACL 2013. The annotations are done on the analytically layer of the Czech to English Data set from the Workshop on Machine Translation 2012 data.

<br/><br/>

  The main purpose of this data set is to give researchers gold level dependency annotations for a data set that contains a gold level translations. This should allow researchers to analyze dependency annotations effect on machine translation.
<br/><br/>

  <hr />

  <strong>License:</strong>
<br/><br/>
  The data sets were made available as part of the shared task for machine translation at WMT 2012. More information can be found at <a href="http://www.statmt.org/wmt12/translation-task.html" target="_blank">http://www.statmt.org/wmt12/translation-task.html</a>.

<br/><br/>
  The data below with our annotations are being made available under the <a href="http://creativecommons.org/licenses/by-nc-sa/3.0/" target="_blank">Creative Commons 3.0-BY-NC-SA license</a>.

<br/><br/>
  <hr />

  <strong>Contact Info:</strong>
<br/><br/>
  If you have any concerns or questions please contact:

<br/><br/>

  <span><strong><a title="Nathan Green" href="http://www.nathangreen.com" target="_blank">Nathan Green</a></strong></span>: nathan@nathangreen.com

<br/><br/>

  <span><strong><a title="Zdeněk Žabokrtský" href="http://ufal.mff.cuni.cz/~zabokrtsky/" target="_blank">Zdeněk Žabokrtský</a></strong></span>: zabokrtsky@ufal.mff.cuni.cz

<br/><br/>

  <hr />

  <strong>Publications:</strong>
<br/><br/>
  If this data set is used in publications we would appreciate if the following is cited.

<br/><br/>

  <strong><a title="[bib]" href="/bib/hytra.bib">[bib]</a> </strong>Nathan Green and Zdeněk Žabokrtský, <em>Improvements to Syntax-based Machine Translation using Ensemble Dependency Parsers. </em>Proceedings of the ACL 2013 Second Workshop on Hybrid Approaches to Translation (HyTra), Sofia, Bulgaria, 2013

<br/><br/>
  <hr />

  <strong>Acknowledgments:</strong>
<br/><br/>
  This research has received funding from the European Commission’s 7th Framework Program (FP7) under grant agreement n° 238405 (CLARA). Additionally, this work has been using language resources developed and/or stored and/or distributed by the LINDAT-Clarin project of the Ministry of Education of the Czech Republic (project LM2010013).

<br/><br/>

  <hr />

  <strong>File Downloads:</strong>
<br/><br/>
  Treex format:  <span ><a title="WMT Treex Annotations" href="/data/treex_annotations_0.1.tgz" target="_blank">Version 0.1</a></span>
<br/><br/>
  CoNLL format: <span ><a title="WMT CoNLL Annotations" href="/data/conll_annotations_0.1.tgz" target="_blank">Version 0.1</a></span>

      </div>
  </Layout>
);

export default IndexPage;
